import { supabase } from '~/services/supabase'
import { Tables } from '~/types/supabase'

const getPagesURLs = async (pages: Tables<'pages'>[], lowRes=false) => {
  const urls: string[] = []
  const settings = lowRes ? {
    transform: {
      quality: 40
    }
  } : {}

  pages.forEach(async (page) => {
    const { data } = await supabase
      .storage
      .from('pages')
      .getPublicUrl(page.file, settings)
    urls.push(data.publicUrl)
    console.log(JSON.stringify(data))
  })

  return urls
}

const getPagesData = async (pagesUrls: string[]) => {
  const pagesData: string[] = []

  const pagesUrlsPromises = pagesUrls.map(async (url) => {
    const response = await fetch(url)
    const buffer = await response.arrayBuffer()
    const base64 = Buffer.from(buffer).toString('base64')
    return base64
  })

  const data = await Promise.all(pagesUrlsPromises)
  pagesData.push(...data)
  return pagesData
}

export {
  getPagesURLs,
  getPagesData
}