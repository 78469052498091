import { createSignal } from 'solid-js'
import { history } from '~/editor/history'
import { FontData, FontFamily } from '~/local-fonts'
import { selectedTypesetTexts } from '~/services/current-document/typeset-texts'
import { Shadow, Stroke, TextStyles, Transform, UpdateStylesOptions } from '~/types/editor/text-styles'
import { Subset } from '~/types/subset'

const defaultStyles: TextStyles = {
  path: 'Style par défaut',
  props: {
    fontFamily: {
      name: 'Comic Neue',
      styles: [{
        family: 'Comic Neue',
        fullName: 'Comic Neue Bold',
        postscriptName: 'Comic-Neue-Bold',
        style: 'Bold'
      }]
    },
    fontVariant: {
      family: 'Comic Neue',
      fullName: 'Comic Neue Bold',
      postscriptName: 'Comic-Neue-Bold',
      style: 'Bold'
    },
    textAlign: 'center',
    fontSize: 24,
    lineHeight: 1,
    letterSpacing: 0,
    fill: '#000000',
    stroke: 'none',
    strokeWidth: 0,
    uppercase: true
  },
  strokes: [],
  transforms: [],
  shadows: []
}

const [textStyles, setTextStyles] = createSignal<TextStyles>(defaultStyles, { equals: false })

const updateStyles = (update: Subset<TextStyles>, options: UpdateStylesOptions = { updateSelected: true, updateDB: true, resetCharStyles: false }) => {
  const updateSelected = options.updateSelected ?? true
  const updateDB = options.updateDB ?? true
  const resetCharStyles = options.resetCharStyles ?? false
  const styles = textStyles()
  const fontFamily = update.props?.fontFamily as FontFamily
  const fontVariant = update.props?.fontVariant as FontData
  const strokes = (update.strokes ?? styles.strokes) as Stroke[]
  const shadows = (update.shadows ?? styles.shadows) as Shadow[]
  const transforms = (update.transforms ?? styles.transforms) as Transform[]
  const newStyles: TextStyles = {
    path: update.path ?? null,
    props: {
      ...styles.props,
      ...update.props,
      fontFamily: fontFamily ?? styles.props.fontFamily,
      fontVariant: fontVariant ?? styles.props.fontVariant
    },
    strokes,
    shadows,
    transforms
  }
  const selectionExists = selectedTypesetTexts().length > 0
  let resetFontSize = false
  if(selectionExists){
    resetFontSize = selectedTypesetTexts()[0].styles.props.fontSize !== newStyles.props.fontSize
  }
  setTextStyles(newStyles)
  if(updateSelected){
    selectedTypesetTexts().forEach(entry => {
      const oldStyles = entry.styles

      if (updateDB){
        console.log("New Style entry in History")
        history.append({
          name: 'updateTypesetText',
          undo: () => {
            if (resetCharStyles) {
              entry.charStyles = []
            }
            if (resetFontSize && oldStyles.props.fontSize) {
              entry.forEachCharStyle(charStyle => {
                charStyle.styles.props.fontSize = oldStyles.props.fontSize
              })
            }
            entry.applyStyles(oldStyles, true)
          },
          redo: () => {
            if (resetCharStyles) {
              entry.charStyles = []
            }
            if (resetFontSize && newStyles.props.fontSize) {
              entry.forEachCharStyle(charStyle => {
                charStyle.styles.props.fontSize = newStyles.props.fontSize
              })
            }
            entry.applyStyles(newStyles, true)
          }
        })
      }      

      if (resetCharStyles) {
        entry.charStyles = []
      }
      if (resetFontSize && newStyles.props.fontSize) {
        entry.forEachCharStyle(charStyle => {
          charStyle.styles.props.fontSize = newStyles.props.fontSize
        })
      }
      entry.applyStyles(newStyles, updateDB)
      
    })
  }
}

type TextStyleUpdater = typeof updateStyles

export {
  textStyles,
  updateStyles,
  defaultStyles,
  TextStyleUpdater
}