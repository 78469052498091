import { Gradient as FabricGradient, Textbox } from 'fabric'
import { Gradient } from '~/types/editor/text-styles'

const getAbsoluteGradient = (object: Textbox, gradient: Gradient) => {
  // Assuming gradient is centered on text object and using the fill method
  // We compute carthesian coords from polar coords
  const radius = (object.width > object.height ? object.width : object.height) / 2
  let coords = {
    x1: radius * Math.cos(gradient.angle),
    y1: radius * Math.sin(gradient.angle),
    x2: -1 * (radius * Math.cos(gradient.angle)),
    y2: -1 * (radius * Math.sin(gradient.angle))
  }
  
  // Offset the coords to place the gradient at the center of the text object
  coords = {
    x1: coords.x1 + radius,
    y1: coords.y1 + radius,
    x2: coords.x2 + radius,
    y2: coords.y2 + radius
  }

  return new FabricGradient({
    type: gradient.type,
    colorStops: gradient.colorStops,
    gradientUnits: 'pixels',
    coords
  })
}

export {
  getAbsoluteGradient
}